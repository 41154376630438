export default [
  {
    component: 'CNavItem',
    name: 'Tableau de bord',
    to: '/dashboard',
    icon: 'cil-speedometer',
    group:1,
  },
  {
    component: 'CNavTitle',
    name: 'Alertes',
    group:3,
  },
  {
    component: 'CNavItem',
    name: 'Gestion des alertes',
    to: '/alerte',
    icon: 'cil-running',
    badge: {
      text: '🤲',
      shape: 'pill',
    },
    group:3,
  },
  {
    component: 'CNavItem',
    name: 'Gestion des tests',
    to: '/testalerte',
    icon: 'cil-running',
    badge: {
      text: '🤲',
      shape: 'pill',
    },
    group:3,
  },
  {
    component: 'CNavItem',
    name: 'Cartographie',
    to: '/cartographie',
    icon: 'cil-locationPin',
    badge: {
      text: '📍',
      shape: 'pill',
    },
    group:3,
  },
  {
    component: 'CNavItem',
    name: 'Statistiques',
    to: '/statistiques',
    icon: 'cil-barChart',
    group:3,
  }, 
  {
    component: 'CNavTitle',
    name: 'Utilisateurs',
    group:2,
  },
  {
    component: 'CNavItem',
    name: 'Utilisateurs actifs',
    to: '/user',
    icon: 'cil-group',
    group:2,
  },
  {
    component: 'CNavItem',
    name: 'Utilisateurs inactifs',
    to: '/deactiveuser',
    icon: 'cil-group',
    group:2,
  },
  {
    component: 'CNavItem',
    name: 'Utilisateurs signalés',
    to: '/user-reported',
    icon: 'cil-group',
    group:2,
  },
  

  {
    component: 'CNavItem',
    name: 'Avis utilisateurs',
    to: '/user-review',
    icon: 'cil-commentSquare',
    group:2,
  },
  {
    component: 'CNavItem',
    name: 'CNI',
    to: '/user-cni',
    icon: 'cilBadge',
    group:2,
  },


  //General menu

  {
    component: 'CNavTitle',
    name: 'Publicités',
    group:8,
  },
      //Ads sub-menu

  {
    component: 'CNavItem',
    name: 'Gestion des pubs',
    to: '/ads',
    icon: 'cil-bell',
    group:8,
  },
  // {
  //   component: 'CNavItem',
  //   name: 'Statistiques',
  //   to: '/ads/stat',
  //   icon: 'cil-barChart',
  //   group:8,
  // },


  //partenaires
  {
    component: 'CNavTitle',
    name: 'Partenaires',
    group:7,
  },
  {
    component: 'CNavItem',
    name: 'Gestion des partenaires',
    to: '/listpartenaire',
    icon: 'cil-sign-language',
    group:7,
  },

  {
    component: 'CNavTitle',
    name: 'Boutique',
  },
  {
    component: 'CNavItem',
    name: 'Gestion des produits',
    to: '/product',
    icon: 'cil-cart',
    group:7,
  },
  {
    component: 'CNavItem',
    name: 'Gestion des commandes',
    to: '/commande',
    icon: 'cil-credit-card',
    group:4,
  },

  {
    component: 'CNavTitle',
    name: 'Fiches pédagogiques',
    group:5,
  },

  {
    component: 'CNavItem',
    name: 'Gestion des articles',
    to: '/article',
    icon: 'cil-newspaper',
    group:5,
  },
  {
    component: 'CNavTitle',
    name: 'Administrateurs',
    group:6,
  },
  {
    component: 'CNavItem',
    name: 'Gestion des admins',
    to: '/admin',
    icon: 'cil-group',
    group:6,
  },

  

  // {
  //   component: 'CNavItem',
  //   name: 'Download CoreUI',
  //   href: 'http://coreui.io/vue/',
  //   icon: { name: 'cil-cloud-download', class: 'text-white' },
  //   _class: 'bg-success text-white',
  //   target: '_blank'
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Try CoreUI PRO',
  //   href: 'http://coreui.io/pro/vue/',
  //   icon: { name: 'cil-layers', class: 'text-white' },
  //   _class: 'bg-danger text-white',
  //   target: '_blank'
  // }
]
