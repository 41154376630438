<template>
<!-- <div class="bg-light min-vh-100 d-flex flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol :md="6">
          <div class="clearfix">
            <h1 class="float-start display-3 me-4">404</h1>
            <h4 class="pt-3">Oops! Vous êtes perdu.</h4>
            <p class="text-medium-emphasis float-start">
              La page que vous recherchez n'a pas été trouvée.
            </p>
          </div>

        </CCol>
      </CRow>
    </CContainer>
  </div> -->
 <div class="bg-light min-vh-100 d-flex flex-row justify-content-center align-items-center">

	<div class="d-flex align-items-center">
   <CContainer>
        <CRow>
                  <CCol :md="12">
			<CCard class="py-5 shadow-sm maCad">
				<div class="row g-0 ">
					<div class="col col-xl-5">
						<div class="card-body p-4">
							<h1 class="display-1"><span class="text-primary">4</span><span class="text-danger">0</span><span class="text-success">4</span></h1>
							<h2 class="font-weight-bold display-4">Perdu dans l'espace</h2>
							<p>Vous avez atteint le bord de l'univers.
								<br>La page que vous avez demandée est introuvable.
								<br>Ne vous inquiétez pas et revenez à la page précédente.</p>
							<div class="  d-flex flex-wrap mt-5">
                 <a @click="$router.go(-1)" class="btn btn-primary btn-lg px-md-5 radius-30 mb-2">Page précédente</a>
								<a  href="#"  class="btn btn-outline-dark btn-lg ms-3 px-md-5 radius-30 mb-2">Page d'accueil</a>
							</div>
						</div>
					</div>
					<div class="col-xl-7">
						<img src="https://cdn.searchenginejournal.com/wp-content/uploads/2019/03/shutterstock_1338315902.png" class="img-fluid" alt="">
					</div>
				</div>

			</CCard>
                  </CCol>
        </CRow>
   </CContainer>
	</div>

</div>

</template>

<script>
export default {
  name: 'Page404',
}
</script>
<style>
.maCad{
  border: none!important;
}
</style>
