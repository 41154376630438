<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0 my-auto" :caret="false">
      <CIcon  height="48" icon="cil-bell" size="xl" class="mt-2" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0" >
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Alerte
      </CDropdownHeader>
     <CDropdownItem class="arriereCoul">
        <CIcon  icon="cil-bell" />Niveau 1
        <CBadge color="info" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-envelope-open" /> Niveau 2
        <CBadge color="success" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-task" /> Niveau 3
        <CBadge color="danger" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>

      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Santé
      </CDropdownHeader>
      <CDropdownItem> <CIcon icon="cil-user" /> Niveau 1 </CDropdownItem>
      <CDropdownItem> <CIcon icon="cil-settings" /> Niveau 2 </CDropdownItem>
      <CDropdownItem>
        <CIcon icon="cil-dollar" /> Niveau3
        <CBadge color="secondary" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>

      <CDropdownDivider />


    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import avatar from '@/assets/images/sama_logo_unique.svg'
import { userService } from '@/helpers/user.service'
import router from '@/router/index'
export default {
  name: 'AppHeaderDropdownNotif',
  setup() {
    return {
      avatar: avatar,
      itemsCount: 42,
    }
  },

   methods: {
    logOut(){

      userService.logout();
          router.push('/pages/login');
    }
   }
}
</script>
<style>
.linkLog{
 color: blue;
  text-decoration: none;
}



/* visited link */
.liens {
text-decoration: none !important;
color: black !important;

}


.arriereCoul{
  background-color: #d7d7de!important;
}

/*  .dropdown-menu {
  min-width: 20rem!important;
} */
</style>
