import { createApp } from 'vue'
import print from 'vue3-print-nb'
import App from './App.vue'
import router from './router'
import store from './store'
import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsCallout from '@/components/DocsCallout'
import DocsExample from '@/components/DocsExample'
import { CDataTable } from '@coreui/vue'
import { CSmartTable,CSmartPagination } from '@coreui/vue-pro'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { faTrashRestore } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import snotify from 'vue3-snotify';
import 'vue3-snotify/style';
import axios from 'axios'
import VueAxios from 'vue-axios'
import BootstrapVue3 from 'bootstrap-vue-3'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import VueSkeletonLoader from 'skeleton-loader-vue';
import {
  cilArrowBottom,
  cilArrowRight,
  cilArrowTop,
  cilBan,
  cilBasket,
  cilBell,
  cilCalculator,
  cilCalendar,
  cilUser,
  cilPhone,
  cilHome,
  cilHospital,
  cilHouse,
  cilAddressBook,
  cilContact,
  cilClipboard,
  cilBullhorn,
  cilBarChart,
  cilEye,
  cilCommentSquare,
  cilMeh,
  cilMoodGood,
  cilBadge
  
} from '@coreui/icons'

const cicons = {
  cilArrowBottom,
  cilArrowRight,
  cilArrowTop,
  cilBan,
  cilBasket,
  cilBell,
  cilCalculator,
  cilCalendar,
  cilUser,
  cilPhone,
  cilHome,
  cilHospital,
  cilHouse,
  cilAddressBook,
  cilContact,
  cilClipboard,
  cilBullhorn,
  cilBarChart,
  cilEye,
  cilCommentSquare,
  cilMeh,
  cilMoodGood,
  cilBadge
}









library.add(faUserSecret, faEdit, faTimesCircle, faTrashRestore)

const app = createApp(App)



// Global instruction 
app.use(print)
app.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDlf2gLsEz37Lsukm_tg26ew5H7QViHSpo",
    libraries: "places"
  }
})
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(VueAxios, axios)
app.provide('icons', cicons)
app.component('CIcon', CIcon)
app.use(snotify);
app.provide('icons', icons);
app.component('CIcon', CIcon);
app.component('vue-skeleton-loader', VueSkeletonLoader);
app.component('DocsCallout', DocsCallout)
app.component('DocsExample', DocsExample)
app.component('CDataTable', CDataTable)
app.component('FontAwesomeIcon', FontAwesomeIcon)
app.component('CSmartTable', CSmartTable)
app.component('CSmartPagination', CSmartPagination)
app.use(BootstrapVue3)
app.mount('#app')
